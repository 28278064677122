<template>
    <div :class="$style.HomeGenplan">
        <transition
            name="fade"
            mode="out-in"
        >
            <Genplan
                v-if="genplan"
                :key="type"
                :type="type"
                :image="genplan.image"
                :preview="genplan.preview"
                :width="genplan.width"
                :height="genplan.height"
                :is-hide-tag-style="genplan?.white_style ?? false"
                :objects="objects"
                :presales="presales"
                :infrastructures="infrastructures"
                :locations="locations"
                @loaded="$emit('loaded')"
                @onObjectClick="onObjectClick"
            >
                <template #tooltip="{data}">
                    <component
                        :is="tooltipComponent"
                        v-bind="data"
                    />
                </template>

                <template #attachments="{disabled}">
                    <component
                        :is="attachmentsComponent"
                        :disabled="disabled"
                        v-bind="attachments"
                    />

                    <div v-if="!isNaN(parseFloat(genplan.azimuth))" :class="$style.compass">
                        <TheCompass :rotate="genplan.azimuth" :class="$style.compassArrow" />
                    </div>
                </template>
            </Genplan>

            <SkeletonLoader v-else />
        </transition>
    </div>
</template>

<script>
import { getNodeArray, roundToMillions, plural, convertToRomeQuarter, splitThousands } from '~/assets/js/utils.js';

import allProjects from '~/queries/pages/project/genplan/allProjects.graphql';
import allUrbanBlocks from '~/queries/pages/project/genplan/allUrbanBlocks.graphql';
import allBuildings from '~/queries/pages/project/genplan/allBuildings.graphql';
import allSections from '~/queries/pages/project/genplan/allSections.graphql';
import allFloors from '~/queries/pages/project/genplan/allFloors.graphql';
import allFlats from '~/queries/pages/project/genplan/allFlats.graphql';

import Genplan from '~/components/genplan/Genplan.vue';
import ProjectGenplanUrbanNavigation from '~/components/pages/project/architecture/attachments/ProjectGenplanUrbanNavigation.vue';
import ProjectGenplanCorpusNavigation from '~/components/pages/project/architecture/attachments/ProjectGenplanCorpusNavigation.vue';
import ProjectGenplanUrbanTooltip from '~/components/pages/project/architecture/attachments/ProjectGenplanUrbanTooltip.vue';
import ProjectGenplanCorpusTooltip from '~/components/pages/project/architecture/attachments/ProjectGenplanCorpusTooltip.vue';
import SkeletonLoader from '~/components/common/SkeletonLoader.vue';
import TheCompass from '~/components/common/TheCompass.vue';

import KeyIcon from '~/assets/icons/home/key.svg?inline';
import { mapGetters } from 'vuex';

export default {
    name: 'HomeGenplan',

    components: {
        TheCompass,
        Genplan,
        ProjectGenplanUrbanNavigation,
        ProjectGenplanCorpusNavigation,
        ProjectGenplanUrbanTooltip,
        ProjectGenplanCorpusTooltip,
        SkeletonLoader,
    },

    props: {
        isDigital: {
            type: Boolean,
            default: false,
        },
    },

    data() { // такой подход к хранению данных, чтобы не перетирать разные ГП (данные из родительского ГП нужны для дочернего)
        return {
            KeyIcon,

            urbansData: {
                genplan: null,
                objects: null,
                attachments: null,
            },

            corpusesData: {
                genplan: null,
                objects: null,
                attachments: null,
            },

            sectionsData: {
                genplan: null,
                objects: null,
                attachments: null,
            },

            floorsData: {
                genplan: null,
                objects: null,
                attachments: null,
            },

            type: 'urban', // urban || corpus || section || floor
            currentUrbanId: null,
            currentCorpusId: null,
            currentSectionId: null,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
            isWebpSup: 'device/getIsWebpSupported',
        }),

        ex() {
            return this.isWebpSup ? 'webp' : 'png';
        },

        attachmentsComponent() { // в зависимости от типа ГП выводим нужный компонент навесного управления
            switch (this.type) {
                case 'urban':
                    return ProjectGenplanUrbanNavigation;
                case 'corpus':
                    return ProjectGenplanCorpusNavigation;
                default:
                    return ProjectGenplanUrbanNavigation;
            }
        },

        tooltipComponent() { // в зависимости от типа ГП выводим нужный компонент тултипа
            switch (this.type) {
                case 'urban':
                    return ProjectGenplanUrbanTooltip;
                case 'corpus':
                    return ProjectGenplanCorpusTooltip;
                default:
                    return ProjectGenplanUrbanTooltip;
            }
        },

        genplan() { // в зависимости от типа ГП отдает нужный ГП
            switch (this.type) {
                case 'urban':
                    return this.urbansData.genplan;
                case 'corpus':
                    return this.corpusesData.genplan;
                default:
                    return this.urbansData.genplan;
            }
        },

        objects() { // в зависимости от типа ГП отдает нужные объекты
            switch (this.type) {
                case 'urban':
                    return this.urbansData.objects;
                case 'corpus':
                    return this.corpusesData.objects;
                default:
                    return this.urbansData.objects;
            }
        },

        presales() { // урбан блоки предпродажи
            switch (this.type) {
                case 'urban':
                    return this.urbansData.presales;
                default:
                    return [];
            }
        },

        infrastructures() { // пины инфраструктуры
            switch (this.type) {
                case 'urban':
                    return this.urbansData.infrastructures;
                default:
                    return [];
            }
        },

        locations() { // указатели (метро / сквер и т.д.)
            switch (this.type) {
                case 'urban':
                    return this.urbansData.locations;
                default:
                    return [];
            }
        },

        attachments() { // в зависимости от типа ГП отдает нужный объект с данными для навесного управления
            switch (this.type) {
                case 'urban':
                    return this.urbansData.attachments;
                case 'corpus':
                    return this.corpusesData.attachments;
                default:
                    return this.urbansData.attachments;
            }
        },
    },

    async mounted() {
        await this.fetchUrbansData();
    },

    methods: {
        async fetchUrbansData() { // запрашиваем данные по ГП убран-блоков
            try {
                const [allProjectsResponse, allUrbanBlocksResponse] = await Promise.all([
                    this.$axios.$post('/graphql/', {
                        query: allProjects.loc.source.body,
                    }),
                    this.$axios.$post('/graphql/', {
                        query: allUrbanBlocks.loc.source.body,
                    }),
                ]);

                const genplan = getNodeArray(allProjectsResponse.data, 'allProjects')[0];
                const objects = getNodeArray(allUrbanBlocksResponse.data, 'allUrbanBlocks');
                const pins = getNodeArray(allProjectsResponse.data, 'allProjects')[0]?.mainPageGenplanPins || [];
                const presales = genplan.presaleUbGenplan || [];

                // Фильтрация пинов из одной сущности
                // Пины инфраструктуры
                const infrastructures = pins
                    .filter(infrastructure => {
                        const {
                            icon,
                            title,
                            point,
                            distance,
                            azimuth,
                        } = infrastructure;

                        const isInfrastructure =
                            icon &&
                            title &&
                            !distance &&
                            azimuth === null &&
                            point.length === 2;

                        return isInfrastructure;
                    });

                // Пины указателей
                const locations = pins
                    .filter(locations => {
                        const {
                            icon,
                            title,
                            point,
                            distance,
                            azimuth,
                        } = locations;

                        const isLocation =
                            icon &&
                            title &&
                            distance &&
                            azimuth !== null &&
                            point.length === 2;

                        return isLocation;
                    });

                this.currentUrbanId = null;
                this.currentCorpusId = null;
                this.currentSectionId = null;

                this.urbansData.genplan = {
                    preview: genplan.planProjectPagePreview,
                    image: this.$image.gen(genplan.planProjectPage, { q: 80, fit: false, ex: this.ex, w: 2880 }),
                    width: genplan.planProjectPageWidth,
                    height: genplan.planProjectPageHeight,
                    azimuth: genplan.genplanAzimuth,
                };

                this.urbansData.objects = objects.map(object => ({
                    id: object.id,
                    type: 'object',
                    slug: object.slug,
                    label: {
                        name: object.slug?.replace('-', ' ') || object.name,
                        highlighting: object.highlighting,
                        left: object.descriptionPoint[0],
                        top: object.descriptionPoint[1],
                    },
                    isSalesStart: object?.isSalesStart || false,
                    tooltip: this.createUrbanTooltipData(object),
                    modal: this.createUrbanModalData(object),
                    mobileModal: this.createUrbanMobileModalData(object),
                    genplan: { // эти данные нужны для генплана корпуса
                        image: this.$image.gen(object.plan, { q: 80, fit: false, ex: this.ex, w: 2880 }),
                        preview: object.planPreview,
                        width: object.genPlanWidth,
                        height: object.genPlanHeight,
                        azimuth: object.genplanAzimuth,
                    },
                }));


                this.urbansData.presales = presales.map(presale => ({
                    id: `presale_${presale.id}`,
                    type: 'presale',
                    slug: presale.url,
                    label: {
                        name: presale.title,
                        highlighting: presale.highlighting,
                        left: presale.point[0],
                        top: presale.point[1],
                    },
                }));


                this.urbansData.infrastructures = infrastructures
                    .map(infrastructure => ({
                        id: `infra_${infrastructure.id}`,
                        type: 'infrastructure',
                        label: {
                            top: infrastructure.point[1],
                            left: infrastructure.point[0],
                            icon: infrastructure.icon,
                            name: infrastructure.title,
                            text: infrastructure.text,
                        },
                    }));


                this.urbansData.locations = locations
                    .map(location => ({
                        id: `location_${location.id}`,
                        type: 'location',
                        label: {
                            top: location.point[1],
                            left: location.point[0],
                            name: location.title,
                            icon: location.icon,
                            distance: location.distance,
                            azimuth: location.azimuth,
                        },
                    }));

                this.urbansData.attachments = {
                    link: '',
                    text: 'Выберите урбан-блок, <br /> чтобы изучить подробнее',
                };
                this.type = 'urban';
            } catch (e) {
                console.log('[HomeGenplan] fetchUrbansData:', e);
            }
        },

        async fetchCorpusesData(id) { // запрашиваем данные по ГП копрусов
            try {
                const object = this.urbansData.objects.find(obj => obj.id === id);
                this.currentUrbanId = id;
                this.currentCorpusId = null;
                this.currentSectionId = null;
                const { title } = object.tooltip;
                const { subtitle } = object.tooltip;
                const parentId = object.id;

                const { data } = await this.$axios.$post('/graphql/', {
                    query: allBuildings.loc.source.body,
                    variables: {
                        urbanBlockSlug: object.slug,
                    },
                });

                const objects = getNodeArray(data, 'allBuildings');

                this.corpusesData.genplan = { ...object.genplan };

                this.corpusesData.attachments = {
                    title,
                    subtitle,
                    back: () => this.fetchUrbansData(),
                    link: '',
                };

                objects.forEach(building => {
                    if (
                        building.highlightingTownhouseSection &&
                        Array.isArray(building.pointTownhouseSection) &&
                        building.pointTownhouseSection.length === 2
                    ) {
                        objects.push({
                            ...building,
                            id: building.id + 'town',
                            genplanName: 'Таунхаусы',
                            isTownhouse: true,
                            highlightingUrbanBlock: building.highlightingTownhouseSection,
                            descriptionPointUrbanBlock: building.pointTownhouseSection,
                            zeroRoomTooltip: null,
                            oneRoomTooltip: null,
                            twoRoomTooltip: null,
                            threeRoomTooltip: null,
                            fourRoomTooltip: null,
                        });
                    }
                });

                this.corpusesData.objects = objects.map(object => ({
                    id: object.id,
                    type: 'object',
                    parentId,
                    label: {
                        name: object.genplanName,
                        highlighting: object.highlightingUrbanBlock,
                        left: object.descriptionPointUrbanBlock[0],
                        top: object.descriptionPointUrbanBlock[1],
                        icon: object.isComplete ? KeyIcon : null,
                    },
                    tooltip: this.createCorpusTooltipData(object),
                    modal: this.createCorpusModalData(object, parentId),
                    isSalesStart: object?.isSalesStart || false,
                    isTownhouse: object.isTownhouse || false,
                    genplan: { // эти данные нужны для генплана секции
                        image: this.$image.gen(object.plan, { q: 80, fit: false, ex: this.ex, w: 2880 }),
                        preview: object.planPreview,
                        width: object.genPlanWidth,
                        height: object.genPlanHeight,
                        azimuth: object.genplanAzimuth,
                    },
                    floorPlan: { // данные для модалки плана этажа
                        urban: title,
                        corpus: object.genplanName,
                        order: subtitle,
                        sections: object.sectionSet.map(({ id, number, flatsCount }) => ({
                            value: id,
                            number,
                            label: `Секция ${number}`,
                            flatsCount,
                        })),
                        view: {
                            left: object.planViewLeft,
                            up: object.planViewUp,
                            right: object.planViewRight,
                            down: object.planViewDown,
                        },
                        azimuth: object.azimuth,
                    },
                }));

                this.type = 'corpus';
            } catch (e) {
                console.log('[HomeGenplan] fetchCorpusesData:', e);
            }
        },

        async fetchSectionsData(id) { // запрашиваем данные по ГП секций корпуса
            try {
                const object = this.corpusesData.objects.find(obj => obj.id === id);
                // если корпус является таунхаусом, то переходим в выборщик
                if (object.isTownhouse) {
                    let url = '/flats/?newTypeFlat=280';
                    if (this.isDigital) {
                        url = '/digital-maket/flats/?newTypeFlat=280';
                    }
                    this.$router.push(url);
                    return true;
                }
                this.currentCorpusId = id;
                this.currentSectionId = null;
                const { title } = object.tooltip;
                const subtitle = this.corpusesData.attachments.title;
                const parentId = object.id;

                const { data } = await this.$axios.$post('/graphql/', {
                    query: allSections.loc.source.body,
                    variables: {
                        building: id,
                    },
                });

                const objects = getNodeArray(data, 'allSections');

                // проверка наличие обводок секций для корпуса
                const hasHighlighting = objects.some(obj => obj.highlighting && obj.point.length === 2);
                // и если нет обводок секций то открываем сразу модальное окно
                if (!hasHighlighting) {
                    await this.openFloorPlanModal(this.currentCorpusId, this.currentSectionId);
                    return;
                }

                this.sectionsData.genplan = { ...object.genplan };

                this.sectionsData.attachments = {
                    title,
                    subtitle,
                    back: () => this.fetchCorpusesData(),
                    link: '',
                };

                this.sectionsData.objects = objects.map(object => ({
                    id: object.id,
                    type: 'object',
                    parentId,
                    number: object.number,
                    label: {
                        name: object.title || object.number,
                        highlighting: object.highlighting,
                        left: object.point[0],
                        top: object.point[1],
                    },
                    isTownhouse: object.isTownhouse || false,
                    tooltip: this.createSectionTooltipData(object),
                    genplan: { // эти данные нужны для генплана этажей
                        image: this.$image.gen(object.plan, { q: 80, fit: false, ex: this.ex, w: 2880 }),
                        preview: object.planPreview,
                        width: object.genPlanWidth,
                        height: object.genPlanHeight,
                        azimuth: object.genplanAzimuth,
                    },
                }));

                if (this.sectionsData.objects.length) {
                    await this.openFloorPlanModal(this.currentCorpusId, this.sectionsData.objects[0].id);
                }
            } catch (e) {
                console.log('[HomeGenplan] fetchSectionsData:', e);
            }
        },

        createSpecifications(object) {
            const specifications = [];

            if (object.buildings.length) {
                specifications.push({
                    label: 'Корпусы',
                    value: object.buildings.map(el => el.number).join(', '),
                });
            }

            if (object.floorsNumberFrom && object.floorsNumberTo) {
                specifications.push({
                    label: 'Этажей',
                    value: `${object.floorsNumberFrom}-${object.floorsNumberTo}`,
                });
            }

            if (object.freeFlats) {
                specifications.push({
                    label: 'Свободных квартир',
                    value: object.freeFlats,
                });
            }

            if (object.areaFrom && object.areaTo) {
                specifications.push({
                    label: 'Площадь',
                    value: `${Math.round(object.areaFrom)}—${Math.round(object.areaTo)} м²`,
                });
            }

            // if (object.priceFrom) {
            //     specifications.push({
            //         label: 'Цена',
            //         value: `от ${roundToMillions(object.priceFrom).toString()
            //             .replace('.', ',')} млн руб.`,
            //     });
            // }

            return specifications;
        },

        createUrbanTooltipData(object) { // парсим данные для удобства отображения в тултипе убран-блока
            const specifications = this.createSpecifications(object);

            const result = {
                imagePreview: object.imagePreview,
                imageDisplay: object.imageDisplay,
                title: object.name,
                subtitle: object.completionInfo,
                specifications,
                flatsInfo: this.getFlatsInfo(object),
                next: () => this.fetchCorpusesData(object.id),
            };

            if (!this.isDigital) {
                result.explore = () => this.$router.push(`/project/${object.slug}/`);
            }

            return result;
        },

        createUrbanMobileModalData(object) { // парсим данные для удобства отображения в мобильной модалке убран-блока
            const footerOptions = {};

            if (object.freeFlats === 0) {
                footerOptions.leftBtnClick = () => this.$router.push(`/project/${object.slug}/`);
                footerOptions.leftBtnText = 'Изучить особенности';
            } else {
                footerOptions.leftBtnClick = () => this.$router.push(`/project/${object.slug}/`);
                footerOptions.rightBtnClick = () => this.$router.push(`/flats/?urbanBlock=${object.id}`);
                footerOptions.leftBtnText = 'Изучить особенности';
                footerOptions.rightBtnText = `Показать ${object.freeFlats} ${plural(object.freeFlats, ['квартиру', 'квартиры', 'квартир'])}`;
            }

            return {
                headerOptions: {
                    imagePreview: object.imagePreview,
                    imageDisplay: object.imageDisplay,
                    title: object.name,
                    subtitle: object.completionInfo,
                },

                componentOptions: {
                    flatsInfo: this.getFlatsInfo(object),
                    specifications: this.createSpecifications(object),
                },

                footerOptions,
            };
        },

        createUrbanModalData(object) { // парсим данные для удобства отображения в модалке убран-блока
            const rightBtnUrl = this.isDigital ? `/digital-maket/flats/?urbanBlock=${object.id}` : `/flats/?urbanBlock=${object.id}`;

            return {
                headerOptions: {
                    title: object.name,
                    mobileTitle: 'Особенности убран-блока',
                    firstInfo: object.completionInfo,
                    secondInfo: `${object.freeFlats} ${plural(object.freeFlats, ['квартира', 'квартиры', 'квартир'])} в продаже`,
                },

                componentOptions: {
                    text: object.modalWindowDescriptionUp,
                    slides: object.slidesUrbanBlocks,
                    textBottom: object.modalWindowDescriptionDown,
                },

                footerOptions: {
                    leftBtnClick: () => this.$universalModal.close(),
                    rightBtnClick: () => this.$router.push(rightBtnUrl),
                    leftBtnText: 'Вернуться к выбору<br>урбан-блока',
                    rightBtnText: `Смотреть ${object.freeFlats} ${plural(object.freeFlats, ['квартиру', 'квартиры', 'квартир'])}`,
                },
            };
        },

        createComplexCorpusData(object) { // сложные формирования строк для корпуса
            let order;

            if (object.completionQuarter && object.completionYear && object.isComplete) {
                order = `${convertToRomeQuarter(Number(object.releaseOrder))} очередь, введён в эксплуатацию`;
            } else if (object.completionQuarter && object.completionYear) {
                order = `${convertToRomeQuarter(Number(object.releaseOrder))} очередь, заселение в ${object.completionQuarter} кв. ${object.completionYear}&nbsp;г.`;
            } else {
                order = '';
            }

            let roomsInfo = [
                object.zeroRoomTooltip,
                object.oneRoomTooltip,
                object.twoRoomTooltip,
                object.threeRoomTooltip,
                object.fourRoomTooltip,
            ];

            roomsInfo = roomsInfo.filter(item => item);

            let flatsCount = 0;
            let maxSale = 0;

            roomsInfo.forEach(room => {
                flatsCount += room.count;

                if (room.discount && maxSale < room.discount) {
                    maxSale = room.discount;
                }
            });

            const isSalesSoon = object?.isSalesSoon;
            let postfixes = ['квартира', 'квартиры', 'квартир'];
            if (object.isTownhouse) {
                postfixes = ['таунхаус', 'таунхауса', 'таунхаусов'];
                flatsCount = object.townhouseCount;
            }
            const flatsForSale = !isSalesSoon ? `${flatsCount} ${plural(flatsCount, postfixes)} в продаже` : 'Скоро в продаже';

            const disabled = flatsCount <= 0;
            const flatsInfo = this.getFlatsInfo(object);

            let nextText = 'Выбрать этаж';
            if (object.isTownhouse) {
                nextText = 'Выбрать таунхаус';
            }

            return {
                order,
                flatsCount,
                maxSale,
                flatsForSale,
                flatsInfo,
                disabled,
                nextText,
            };
        },

        getFlatsInfo(object) {
            const flatsInfo = [];

            if (object.zeroRoomTooltip && object.zeroRoomTooltip.count) {
                flatsInfo.push({
                    label: 'Студии',
                    count: object.zeroRoomTooltip.count,
                    value: `от ${roundToMillions(object.zeroRoomTooltip.price).toString()} млн ₽`,
                });
            }
            if (object.oneRoomTooltip && object.oneRoomTooltip.count) {
                flatsInfo.push({
                    label: '1-комн.',
                    count: object.oneRoomTooltip.count,
                    value: `от ${roundToMillions(object.oneRoomTooltip.price).toString()} млн ₽`,
                });
            }
            if (object.twoRoomTooltip && object.twoRoomTooltip.count) {
                flatsInfo.push({
                    label: '2-комн.',
                    count: object.twoRoomTooltip.count,
                    value: `от ${roundToMillions(object.twoRoomTooltip.price).toString()} млн ₽`,
                });
            }
            if (object.threeRoomTooltip && object.threeRoomTooltip.count) {
                flatsInfo.push({
                    label: '3-комн.',
                    count: object.threeRoomTooltip.count,
                    value: `от ${roundToMillions(object.threeRoomTooltip.price).toString()} млн ₽`,
                });
            }
            if (object.fourRoomTooltip && object.fourRoomTooltip.count) {
                flatsInfo.push({
                    label: '4-комн.',
                    count: object.fourRoomTooltip.count,
                    value: `от ${roundToMillions(object.fourRoomTooltip.price).toString()} млн ₽`,
                });
            }
            if (object.isTownhouse && object.townhouseCount) {
                flatsInfo.push({
                    label: 'Таунхаус',
                    count: object.townhouseCount,
                    value: `от ${roundToMillions(object.townhouseMinPrice).toString()} млн ₽`,
                });
            }

            return flatsInfo;
        },

        createCorpusTooltipData(object) { // парсим данные для удобства отображения в тултипе корпуса
            const { order, maxSale, flatsForSale, flatsInfo, nextText, disabled } = this.createComplexCorpusData(object);

            let next = () => this.fetchSectionsData(object.id);
            if (object.isTownhouse) {
                let url = '/flats/?newTypeFlat=280';
                if (this.isDigital) {
                    url = '/digital-maket/flats/?newTypeFlat=280';
                }
                next = () => this.$router.push(url);
            }

            return {
                title: object.genplanName,
                sale: maxSale,
                finishing: object.decoration,
                order,
                flatsForSale,
                flatsInfo,
                disabled,
                isSalesStart: object?.isSalesStart || false,
                explore: object.isTownhouse ? null : () => this.openModal(object.id),
                next,
                nextText,
            };
        },

        createCorpusModalData(object, parentId) { // парсим данные для удобства отображения в модалке корпуса
            const { order, flatsForSale, flatsCount, disabled } = this.createComplexCorpusData(object);
            const url = `/flats/?urbanBlock=${parentId}&building=${object.id}`;
            const rightBtnUrl = this.isDigital ? '/digital-maket' + url : url;

            const footerOptions = {};

            if (disabled) {
                footerOptions.leftBtnClick = () => this.$universalModal.close();
                footerOptions.leftBtnText = 'Вернуться к выбору корпуса';
            } else {
                footerOptions.leftBtnClick = () => this.$universalModal.close();
                footerOptions.rightBtnClick = () => this.$router.push(rightBtnUrl);
                footerOptions.leftBtnText = 'Вернуться к выбору корпуса';
                footerOptions.rightBtnText = `Смотреть ${flatsCount} ${plural(flatsCount, ['квартиру', 'квартиры', 'квартир'])}`;
            }

            return {
                headerOptions: {
                    title: object.genplanName,
                    mobileTitle: 'Особенности корпуса',
                    firstInfo: order,
                    secondInfo: flatsForSale,
                },

                componentOptions: {
                    text: object.modalWindowDescriptionUp,
                    slides: object.slides,
                    textBottom: object.modalWindowDescriptionDown,
                },

                footerOptions,
            };
        },

        createSectionTooltipData(object) { // парсим данные для удобства отображения в тултипе секции
            const { order, maxSale, flatsForSale, flatsInfo, nextText } = this.createComplexSectionData(object);

            let next = () => this.fetchFloorsData(object.id);
            if (object.isTownhouse) {
                let url = '/flats/?newTypeFlat=280';
                if (this.isDigital) {
                    url = '/digital-maket/flats/?newTypeFlat=280';
                }
                next = () => this.$router.push(url);
            }

            return {
                title: object.isTownhouse ? object.title : `Секция ${object.number}`,
                sale: maxSale,
                finishing: object.decoration,
                order,
                flatsForSale,
                flatsInfo,
                next,
                nextText,
            };
        },

        createComplexSectionData(object) { // сложные формирования строк для секции
            return this.createComplexCorpusData(object);
        },

        openModal(id) { // универсальная модалка
            const object = this.objects.find(obj => obj.id === id);
            const { componentOptions, headerOptions, footerOptions } = object.modal;

            this.$universalModal.open({
                header: () => import('~/components/common/modals/universal-modal/headers/TitleInfoHeader.vue'),
                headerOptions,

                component: () => import('~/components/common/modals/universal-modal/bodies/TitleTextSliderTextBody.vue'),
                componentOptions,

                footer: () => import('~/components/common/modals/universal-modal/footers/GrayBlackButtonsFooter.vue'),
                footerOptions,
            });
        },

        openUrbanMobileModal(id) { // открываем модалку урбан-блока на мобилке
            const object = this.objects.find(obj => obj.id === id);
            const { componentOptions, headerOptions, footerOptions } = object.mobileModal;

            this.$universalModal.open({
                header: () => import('~/components/common/modals/universal-modal/headers/ImageTitleSubtitleHeader.vue'),
                headerOptions,

                component: () => import('~/components/common/modals/universal-modal/bodies/TextSpecificationsBody.vue'),
                componentOptions,

                footer: () => import('~/components/common/modals/universal-modal/footers/GrayBlackButtonsFooter.vue'),
                footerOptions,
            });
        },

        async onSectionChange({ objectId, activeSection, sections, floorId }) { // при смене секции в модалке плана этажа
            const floorsResponse = await this.$axios.$post('/graphql/', {
                query: allFloors.loc.source.body,
                variables: {
                    building: objectId,
                    section: activeSection,
                },
            });

            // отфильтровываем по наличию планировки этажа (на бою у всех этажей должны быть планировки)
            // если дом односекционный, планировка лежит в корне floor, если несколько секций, то планировка лежит в floorsectionSet
            // 0 элемент массива, потому что мы всегда запрашиваем конкретную секцию, и поэтому в floorsectionSet 1 элемент
            const floorsData = getNodeArray(floorsResponse.data, 'allFloors')
                .filter(floor => floor.floorsectionSet.length && (floor.plan || floor.floorsectionSet[0]?.plan));

            const plans = floorsData.map(floor => ({
                id: floor.id,
                plan: floor.floorsectionSet[0].plan || floor.plan,
                planHeight: floor.floorsectionSet[0].planHeight || floor.planHeight,
                planWidth: floor.floorsectionSet[0].planWidth || floor.planWidth,
            }));

            const floors = floorsData.map(floor => ({
                label: floor.number,
                value: floor.id,
            }));

            const activeFloor = floors.find(fl => fl.value === floorId) ?? floors[0];

            const flats = await this.onFloorChange({ objectId, activeFloor, activeSection, sections });

            return {
                activeSection,
                floors,
                activeFloor: activeFloor.value,
                plans,
                flats,
            };
        },

        async onFloorChange({ objectId, activeFloor, activeSection, sections }) { // при смене этажа в модалке плана этажа
            const flatsResponse = await this.$axios.$post('/graphql/', {
                query: allFlats.loc.source.body,
                variables: {
                    building: objectId,
                    floorMin: activeFloor.label,
                    floorMax: activeFloor.label,
                    section: sections.find(section => section.value === activeSection).number,
                    status: '1',
                },
            });

            const flatsData = getNodeArray(flatsResponse.data, 'allFlats');

            const flats = flatsData.map(flat => this.createFlatData(flat));

            return flats;
        },

        createFlatData(flat) { // парсим данные для тултипа на плане этажа
            let name = '';

            switch (flat.type) {
                case 'Сити-хаус':
                    name = 'Ситихаус';
                    break;
                case 'Пентхаус':
                    name = 'Пентхаус';
                    break;
                default:
                    name = flat.rooms === 0 ? 'Студия' : `${flat.rooms}-комн.`;
                    break;
            }

            const area = flat.area ? `${flat.area.toString().replace('.', ',')} м²` : '';

            const price = flat.price ? `${splitThousands(flat.price)} ₽` : '';

            const originPrice = flat.originPrice ? `${splitThousands(flat.originPrice)} ₽` : '';

            const sale = flat.salePercent !== 0 ? `скидка ${flat.salePercent}%` : '';

            let advantages = [];

            if (flat.usp && flat.usp.length) {
                advantages = flat.usp.map(item => item.text);
            }

            if (flat.decoration?.title) {
                advantages.push(flat.decoration.title);
            }

            if (sale) {
                advantages.push('Скидка');
            }

            if (flat.type === 'Сити-хаус') {
                advantages.push('Отдельный персональный вход');
                advantages.push('Высокие потолки от 5,14м');
                advantages.push('Фасадное остекление');
            }

            advantages.sort((a, b) => a.length - b.length);

            return {
                id: flat.id,
                slug: flat.slug,
                highlighting: flat.highlighting,
                name,
                area,
                price,
                originPrice,
                sale,
                advantages,
            };
        },

        async openFloorPlanModal(corpusId, sectionId, floorId) { // открываем модалку плана этажа
            try {
                const currentCorpus = this.corpusesData.objects.find(obj => obj.id === corpusId);
                const { urban, corpus, order, sections, view, azimuth } = currentCorpus.floorPlan;
                const currentSection = sections.find(item => item.value === sectionId);
                const {
                    activeSection,
                    floors,
                    activeFloor,
                    plans,
                    flats,
                } = await this.onSectionChange({
                    objectId: corpusId,
                    activeSection: currentSection ? currentSection.value : sections[0].value,
                    sections,
                    floorId,
                });

                const onSectionChange = async value => { // для удобства
                    try {
                        const { activeSection, floors, activeFloor, plans, flats } = await this.onSectionChange({
                            objectId: corpusId,
                            activeSection: value,
                            sections,
                        });

                        return {
                            componentOptions: {
                                activeSection,
                                floors,
                                activeFloor,
                                plans,
                                flats,
                            },
                        };
                    } catch (e) {
                        console.log('[HomeGenplan] onSectionChange:', e);
                    }
                };

                const onFloorChange = async ({ activeSection, activeFloor }) => { // для удобства
                    try {
                        const flats = await this.onFloorChange({
                            objectId: corpusId,
                            activeFloor,
                            activeSection,
                            sections,
                        });

                        return {
                            componentOptions: {
                                activeFloor: activeFloor.value,
                                flats,
                            },
                        };
                    } catch (e) {
                        console.log('[HomeGenplan] onFloorChange:', e);
                    }
                };

                this.$fullScreenModal.open({
                    component: () => import('~/components/genplan/floor-plan/FloorPlan.vue'),
                    componentOptions: {
                        objectId: corpusId,
                        parentId: currentCorpus.parentId,
                        urban,
                        corpus,
                        order,
                        sections,
                        activeSection,
                        onSectionChange: async value => this.$fullScreenModal.changeData(await onSectionChange(value)),
                        floors,
                        activeFloor,
                        onFloorChange: async value => this.$fullScreenModal.changeData(await onFloorChange(value)),
                        plans,
                        flats,
                        view,
                        azimuth,
                        isDigital: this.isDigital,
                    },
                });
            } catch (e) {
                console.log('[HomeGenplan] openFloorPlanModal:', e);
            }
        },

        onObjectClick(id) { // handler клика по объектам на ГП
            if (this.$device.isDesktop && !this.isIpadPro) {
                switch (this.type) {
                    case 'urban':
                        return this.fetchCorpusesData(id);
                    case 'corpus':
                        return this.fetchSectionsData(id);
                    default:
                        return this.fetchUrbansData();
                }
            } else {
                this.openUrbanMobileModal(id);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .HomeGenplan {
        width: 100%;
        height: 70rem;
        min-height: 70rem;

        @include respond-to(sm) {
            height: 76rem;
            min-height: 76rem;
        }

        @include respond-to(xxs) {
            height: 56rem;
            min-height: 56rem;
        }

        .compass {
            position: absolute;
            right: 6.4rem;
            bottom: 3.2rem;
            width: 7.2rem;
            height: 7.2rem;
            padding: 0;
            border-radius: 50%;
            background-color: $base-0;

            @include respond-to(xs) {
                right: 2rem;
                bottom: 2rem;
                width: 5.8rem;
                height: 5.8rem;
            }
        }

        .compassArrow {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -3.2rem;
            margin-left: -3.2rem;

            @include respond-to(xs) {
                margin-top: -2.4rem;
                margin-left: -2.4rem;
            }
        }
    }
</style>
