<template>
    <div :class="$style.AdvantagesList">
        <AdvantagesAccessibility :accessibility="accessibility"/>
        <div
            v-if="!$device.isDesktop"
            :class="$style.title"
        >
            Преимущества
        </div>

        <div
            v-if="!$device.isDesktop && tabs.length > 1"
            :class="[$style.select, [{[$style['_isOpen']]: isTabsShown}]]"
            @click="handleCategorySelect"
        >
            {{ activeCategory }}

            <ArrowDownIcon
                :class="$style.arrow"
            />
        </div>

        <div ref="HomeAdvantagesList" :class="$style.grid">
            <div
                v-for="(card, i) in getCards"
                ref="cards"
                :key="i"
                :class="[
                    $style.card,
                    {[$style.longCard]: utpActiveTab === tabs.length - 1 && i === getCards.length - 2 && $device.isDesktop},
                    {[$style.linkCard]: utpActiveTab === tabs.length - 1 && i === getCards.length - 1 && $device.isDesktop},
                    {[$style._last]: getCards.length % 2 !== 0 && i === getCards.length -1 && activeCategory !== tabs.length},
                ]"
            >
                <AdvantageHeightCard
                    v-if="i === 0"
                    :active="isBlockInView"
                    :card="card"
                    :index="i"
                    :is-flats-card="utpActiveTab === tabs.length - 1"
                />

                <AdvantageListCard
                    v-else-if="card.tags !== 'linkblock'"
                    :card="card"
                    :index="i"
                />

                <AdvantagesLinkCard
                    v-if="utpActiveTab === tabs.length - 1 && i === getCards.length - 1"
                    :card="card"
                />
            </div>
        </div>

        <div
            v-if="$device.isDesktop && tabs.length > 1"
            :class="[$style.tabs, {[$style._shown]: isTabsShown}, {[$style._opacity]: tabsOpacityClass}]"
        >
            <UiTabs
                v-model="utpActiveTab"
                :tabs="tabs"
                dark
            />
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min.js';
import { mapActions, mapGetters, mapState } from 'vuex';

import AdvantageHeightCard
    from '~/components/common/card/advantage-cards/AdvantageHeightCard';
import AdvantageListCard from '~/components/common/card/advantage-cards/AdvantageListCard';
import UiTabs from '~/components/ui/home/UiTabs';
import ArrowDownIcon from '~/assets/icons/arrow-down-solid.svg?inline';
import AdvantagesAccessibility from '~/components/home/advantages/AdvantagesAccessibility.vue';
import AdvantagesLinkCard from '~/components/common/card/advantage-cards/AdvantagesLinkCard.vue';

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'AdvantagesList',

    components: {
        AdvantagesAccessibility,
        AdvantageHeightCard,
        AdvantageListCard,
        UiTabs,
        ArrowDownIcon,
        AdvantagesLinkCard,
    },

    props: {
        accessibility: {
            type: Array,
            default: () => [],

        },
    },

    data() {
        return {
            cardAnimation: null,
            isBlockInView: false,

            tabsAnimation: null,
            tabsOpacityClass: false,
            tabsOpacityTimer: null,
            isTabsShown: false,
        };
    },

    computed: {
        ...mapGetters({
            cards: 'home/gallery/cards',
            isWebpSup: 'device/getIsWebpSupported',
        }),

        ...mapState('home/gallery', [
            'tabs',
            'activeTab',
        ]),

        activeCategory() {
            return this.tabs.find(el => el.value === this.activeTab)?.label || '';
        },

        getCards() {
            const ex = this.isWebpSup ? 'webp' : 'png';
            const noRelativeArray = [];
            for (let i = 0; i < this.cards.length; i++) {
                noRelativeArray.push(Object.assign({}, this.cards[i]));
            }
            return noRelativeArray.map(el => {
                el.imageDisplay = this.$image.gen(el.imageDisplay, { q: 80, fit: false, ex: ex });
                el.imagePreview = this.$image.gen(el.imagePreview, { q: 70, fit: false, ex: ex });
                return el;
            });
        },

        utpActiveTab: {
            get() {
                return this.activeTab;
            },

            set(value) {
                this.setActiveTab(value);
            },
        },
    },

    watch: {
        activeTab() {
            this.$refs.HomeAdvantagesList.scrollIntoView({ behavior: 'smooth', block: 'start' });

            if (this.tabsAnimation) {
                this.tabsAnimation.kill();
                this.tabsAnimation = null;

                this.$nextTick(() => {
                    this.animateTabs();
                });
            }
        },
    },

    mounted() {
        this.animateFirstCard();
        this.animateTabs();
    },

    beforeDestroy() {
        if (this.cardAnimation) {
            this.cardAnimation.kill();
            this.cardAnimation = null;
        }

        if (this.tabsAnimation) {
            this.tabsAnimation.kill();
            this.tabsAnimation = null;
        }
    },

    methods: {
        ...mapActions({
            setActiveTab: 'home/gallery/setActiveTab',
        }),

        animateFirstCard() {
            this.cardAnimation = gsap.timeline({
                defaults: {
                    ease: 'none',
                },

                scrollTrigger: {
                    trigger: this.$refs.cards[0],
                    start: `${this.$refs.cards[0].clientHeight + 100} bottom`,
                    onEnter: () => {
                        this.isBlockInView = true;
                    },
                    onLeave: () => {
                        this.isBlockInView = false;
                    },
                    onEnterBack: () => {
                        this.isBlockInView = true;
                    },
                    onLeaveBack: () => {
                        this.isBlockInView = false;
                    },
                },
            });
        },

        animateTabs() {
            if (this.$device.isDesktop && this.tabs.length > 1) {
                this.tabsAnimation = gsap.timeline({
                    defaults: {
                        ease: 'none',
                    },

                    scrollTrigger: {
                        trigger: this.$refs.HomeAdvantagesList,
                        start: '+=250 bottom',
                        end: `${this.$refs.HomeAdvantagesList.clientHeight + 100} bottom`,
                        onEnter: () => {
                            clearTimeout(this.tabsOpacityTimer);
                            this.isTabsShown = true;
                            this.tabsOpacityClass = true;
                        },
                        onLeave: () => {
                            this.tabsOpacityClass = false;
                            this.tabsOpacityTimer = setTimeout(() => {
                                this.isTabsShown = false;
                            }, 200);
                        },
                        onEnterBack: () => {
                            clearTimeout(this.tabsOpacityTimer);
                            this.isTabsShown = true;
                            this.tabsOpacityClass = true;
                        },
                        onLeaveBack: () => {
                            this.tabsOpacityClass = false;
                            this.tabsOpacityTimer = setTimeout(() => {
                                this.isTabsShown = false;
                            }, 200);
                        },
                    },
                });
            }
        },

        handleCategorySelect() {
            this.$modalNew.open('ModalHomeAdvantagesCategory', { tabs: this.getCards });
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantagesList {
        position: relative;
        padding-top: 9.6rem;
        padding-bottom: 8.6rem;
        background-color: $inversed;

        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 2rem;
        }

        .card {
            height: 48rem;

            &:nth-child(odd) {
                grid-column: 1/4;

                &.longCard {
                    grid-column: 1/5;
                }
            }

            &:nth-child(even) {
                grid-column: 4/7;

                &.linkCard {
                    grid-column: 5/7;
                }
            }

            &:nth-child(1) {
                grid-column: 1/3;
            }

            &:nth-child(2) {
                grid-column: 3/7;
            }

            &:nth-child(3) {
                grid-column: 1/5;
            }

            &:nth-child(4) {
                grid-column: 5/7;
            }

            &._last {
                grid-column: 1/7;
            }
        }

        .tabs {
            position: fixed;
            bottom: 2.4rem;
            left: 50%;
            z-index: -100;
            visibility: hidden;
            opacity: 0;
            transform: translate(-50%, -5rem);
            transition: transform $homeTransition, opacity $homeTransition;

            &._shown {
                z-index: 4;
                visibility: initial;
            }

            &._opacity {
                opacity: 1;
                transform: translate(-50%, 0);
            }
        }

        .title {
            margin-bottom: 3.2rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 3.2rem;
            font-weight: 700;
            line-height: 1.2;

            @include respond-to(xxs) {
                font-size: 2.4rem;
            }
        }

        .select {
            position: relative;
            margin-bottom: 3.2rem;
            padding: 2.25rem 2rem;
            background-color: $base-50;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
            color: $base-400;
        }

        .arrow {
            position: absolute;
            top: 50%;
            right: 2.4rem;
            width: 1.6rem;
            height: 1.6rem;
            color: $home-accent-2;
            transform: translateY(-50%);
            transition: transform .4s ease-in-out;
        }
    }

    @include respond-to(sm) {
        .AdvantagesList {
            padding-top: 3.2rem;
            padding-bottom: 3.2rem;

            .grid {
                gap: 1.6rem;
            }

            .card {
                height: 30.5rem;

                &:nth-child(1),
                &:nth-child(3) {
                    grid-column: 1/4;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    grid-column: 4/7;
                }
            }
        }
    }

    @include respond-to(xs) {
        .AdvantagesList {
            .card {
                height: 26.4rem;

                &:nth-child(odd),
                &:nth-child(even),
                &:nth-child(1),
                &:nth-child(2),
                &._last {
                    grid-column: 1/7;
                }
            }
        }
    }
</style>
