<template>
    <div :class="$style.HomeNews">
        <div class="container-home">
            <h3
                :class="$style.title"
            >
                Новости
            </h3>
        </div>
        <div class="container-home no-pr-sm">
            <div :class="[$style.sliderWrap, {[$style.padding]: arrows}]">
                <div
                    ref="slider"
                    class="swiper-container"
                    :class="$style.slider"
                >
                    <div
                        class="swiper-wrapper"
                    >
                        <div
                            v-for="(card, i) in news"
                            :key="i"
                            class="swiper-slide"
                            :class="$style.card"
                        >
                            <HomeNewsCard :card="card" />
                        </div>

                        <div
                            v-if="$device.isTablet"
                            class="swiper-slide"
                            :class="[$style.card, $style._placeholder]"
                        >
                        </div>
                    </div>

                    <!-- <div
                        v-if="$device.isMobileOrTablet"
                        class="swiper-pagination"
                        :class="$style.pagination"
                    >
                    </div> -->
                    <div
                        v-if="arrows"
                        class="container"
                    >
                        <div :class="$style.sliderButtons">
                            <UiCircleButton
                                ref="prev"
                                :class="$style.sliderBtn"
                                color="white"
                            >
                                <ArrowLeft />
                            </UiCircleButton>

                            <UiCircleButton
                                ref="next"
                                :class="[$style.sliderBtn,$style._reverse]"
                                color="white"
                            >
                                <ArrowLeft/>
                            </UiCircleButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-home">
            <div :class="$style.HomeNewsFooter">
                <UiButton
                    :class="$style.button"
                    link="/center/#news"
                    color="primary"
                >
                    Смотреть все новости
                </UiButton>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.min.js';

import HomeNewsCard from '~/components/home/HomeNewsCard';
import UiButton from '~/components/ui/home/UiButton';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';
import ArrowLeft from '~/assets/icons/arrow-left-solid.svg?inline';

export default {
    name: 'HomeNews',

    components: {
        HomeNewsCard,
        UiButton,
        UiCircleButton,
        ArrowLeft,
    },

    props: {
        news: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            slider: undefined,
        };
    },

    computed: {
        arrows() {
            return this.$device.isDesktop;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.initApp();
        });
    },

    updated() {
        if (this.slider) {
            this.slider.update();
        }
    },

    beforeDestroy() {
        if (this.slider) {
            this.slider.destroy();
        }
    },

    methods: {
        initApp() {
            const options = {
                breakpoints: {
                    320: {
                        slidesPerView: 1.10,
                        spaceBetween: 12,
                        watchOverflow: true,
                    },

                    768: {
                        slidesPerView: 2.25,
                        spaceBetween: 12,
                        watchOverflow: true,
                    },

                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        watchOverflow: true,
                    },

                },

                // pagination: {
                //     el: '.swiper-pagination',
                //     clickable: true,
                // },

                navigation: {
                    prevEl: this.$refs.prev ? this.$refs.prev.$el : null,
                    nextEl: this.$refs.next ? this.$refs.next.$el : null,
                    disabledClass: this.$style._disabled,
                },
            };

            this.slider = new Swiper(this.$refs.slider, options);
        },

    },
};
</script>

<style lang="scss" module>
    .HomeNews {
        width: 100%;
        margin-bottom: 12.8rem;

        @include respond-to(xs) {
            margin-bottom: 6.4rem;
        }
    }

    .sliderWrap {
        position: relative;

        &.padding {
            padding: 0 7.2rem;
        }
    }

    .title {
        @include hc4;

        margin-bottom: 4rem;
        text-align: center;
        color: $base-900;

        @include respond-to(sm) {
            font-size: 3.2rem;
            line-height: 4rem;
        }

        @include respond-to(xxs) {
            margin-bottom: 3.2rem;
            font-size: 2.4rem;
        }
    }

    .card {
        &._placeholder {
            width: calc(100% - 358px - 12px);
        }
    }

    .pagination {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
    }

    :global(.swiper-pagination-bullet) {
        width: 1.6rem;
        height: .2rem;
        margin: 0 .15rem;
        background-color: #dfe0e2;
        transition: $homeTransition;
    }

    :global(.swiper-pagination-bullet-active) {
        background-color: #2f2f2f;
    }

    .slider {
        padding-bottom: 0;
    }

    .sliderButtons {
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .sliderBtn {
        pointer-events: all;

        &._disabled {
            opacity: .44;
            pointer-events: none;
            user-select: none;
        }

        &._reverse {
            transform: rotate(180deg);
        }
    }

    .HomeNewsFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4rem;
    }

    .button {
        @include respond-to(xs) {
            width: 100%;
        }
    }
</style>

<style lang="scss">
    .no-pr-sm {
        @include respond-to(sm) {
            padding-right: 0;
        }
    }
</style>
