<template>
    <div
        class="container-home"
        :class="$style.containerSale"
    >
        <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
            :class="$style.HomeSaleForm"
        >
            <HomeSaleFormTitle
                v-if="title || description"
                :class="$style.HomeSaleFormTitle"
                :title="title"
                :description="description"
            />

            <form
                ref="HomeSaleForm"
                :class="$style.form"
                @submit.prevent="handleSubmit(onSubmit)"
            >
                <div :class="$style.inputsContainer">
                    <UiInput
                        v-model="name"
                        field="имя"
                        name="name"
                        placeholder="Имя"
                        rules="required|cyrillic|min:2|alpha_spaces"
                        :class="$style.input"
                    />

                    <UiInput
                        v-model="email"
                        field="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        rules="required|email"
                        :class="$style.input"
                    />

                    <UiInput
                        v-model="phone"
                        is-phone
                        field="телефон"
                        type="tel"
                        name="phone"
                        placeholder="Телефон"
                        rules="required|phone"
                        :class="$style.input"
                    />

                    <UiButton
                        color="primary"
                        type="submit"
                        :class="$style.button"
                    >
                        {{ buttonLabel }}
                    </UiButton>
                </div>
            </form>

            <div :class="$style.policy">
                <FormPolicy v-model="checkboxes" line />
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import HomeSaleFormTitle from '~/components/home/saleForm/HomeSaleFormTitle';
import UiButton from '@/components/ui/home/UiButton';
import UiInput from '~/components/ui/home/UiInput';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'HomeSaleForm',

    components: {
        FormPolicy,
        HomeSaleFormTitle,
        UiButton,
        UiInput,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: 'Получить консультацию',
        },
    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',

            checkboxes: {
                policy: false,
                marketing: true,
            },
        };
    },

    methods: {
        async onSubmit() {
            try {
                const payload = {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    request_type: 'main_promo',
                    ad: this.checkboxes.marketing,
                };

                await this.$axios.$post('/api/request/', payload);

                if (window.ctw) {
                    window.ctw.createRequest('presale', this.phone.replace(/[^+\d]+/g, '').slice(1), [
                        {
                            name: 'Имя',
                            value: this.name,
                        },
                        {
                            name: 'Email',
                            value: this.email,
                        },
                    ], function(success, data) {
                        console.log(success, data);
                    });

                    console.log('success');
                }

                window.mindbox('async', {
                    operation: 'Website.SubscriptionInNew',
                    data: {
                        customer: {
                            mobilePhone: this.phone,
                            firstName: this.name,
                            email: this.email,
                        },
                    },
                    onSuccess: function() {
                        console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                    },
                    onError: function(error) {
                        console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                    },
                });

                this.resetForm();
                this.openSuccessModal();
            } catch (e) {
                this.openFailureModal();
                console.warn('[HomeSaleForm] onSubmit:', e);
            }
        },

        resetForm() {
            this.$refs.HomeSaleForm.reset();
            this.checkboxes.policy = false;
            this.checkboxes.marketing = true;
            this.name = '';
            this.email = '';
            this.phone = '';
            this.$refs.observer.reset();
        },

        openSuccessModal() {
            this.$modal.open('ModalHomeSaleFormSuccess', {
                title: 'Спасибо, заявка принята!',
                description: 'Вступайте в чат-бот и узнавайте новости первыми',
                type: 'main_promo',
                hasTelegramLink: true,
            });
        },

        openFailureModal() {
            this.$modal.open('ModalHomeSaleFormSuccess', {
                title: 'Произошла ошибка',
                description: 'Попробуйте повторить попытку снова или перезагрузите страницу!',
                hasTelegramLink: false,
                isError: true,
            });
        },

    },
};
</script>

<style lang="scss" module>
    .containerSale {
        position: relative;
        z-index: 10;
        background: #fff;
    }

    .HomeSaleForm {
        display: flex;
        flex-direction: column;
        padding-right: 6.4rem;
        padding-left: 6.4rem;

        @include respond-to(sm) {
            padding-right: 2.4rem;
            padding-left: 2.4rem;
        }

        .HomeSaleFormTitle {
            margin-bottom: 6.4rem;

            @include respond-to(sm) {
                margin-bottom: 3.6rem;
            }
        }

        .inputsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 12px;

            @include respond-to(sm) {
                grid-template-columns: 1fr;
            }
        }

        .input {
            z-index: 1;
        }

        .button {
            z-index: 1;
            width: 100%;
            height: 5.6rem;
        }

        .policy {
            @include old-p6;

            margin: 3.2rem auto 0 auto;
            text-align: center;
            color: $home-accent-5;

            @include respond-to(sm) {
                margin: 3.2rem auto 0 auto;
            }

            @include respond-to(xs) {
                margin: 2.4rem auto 0 auto;
            }
        }
    }
</style>
